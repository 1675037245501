import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { Seo, Text } from 'components';
import ArrowDownIcon from 'assets/icons/arrow-drop-down-down.svg';
import ArrowRightIcon from 'assets/icons/arrow-drop-down-right.svg';
import { tablet, useQuery } from 'styles/breakpoints';

import Layout from 'layouts/Layout';

interface Item {
  question: string;
  answer: string[];
}

interface PeopleAskUsProps {
  title: string;
  list: Item[];
}

const Container = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  @media ${tablet} {
    gap: 2rem;
    padding: 0;
  }
`;

const QuestionContainer = styled.div<{ expanded: boolean }>`
  overflow: ${({ expanded }) => (expanded ? 'auto' : 'hidden')};
  ::-webkit-scrollbar {
    width: 0;
  }
  transition: max-height 0.6s ease;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.light0};
  cursor: pointer;
  padding: 1.5rem 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #e4e4eb;
  justify-content: space-between;
`;

const Question = styled(Text).attrs({
  type: 'tabActive',
  color: 'dark80',
})`
  font-size: 1.125rem;
  transition: color 0.2s ease;
  font-family: Inter;
  font-weight: 600;
  &:hover {
    color: ${({ theme }) => theme.colors.dark60};
  }
  /* fixes large text on ios issue */
  -webkit-text-size-adjust: none;
`;

const List = styled.div`
  max-width: 100%;
  width: 832px;
  div:nth-child(1n):not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const AnswerContainer = styled.div<{ expanded: boolean }>`
  max-height: ${({ expanded }) => (expanded ? 700 : 0)}px;
  transition: max-height 0.4s ease;
  margin-top: 1.06rem;
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
  /* hide scrollbar for Chrom, Safari */
  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`;

const ArrowDown = styled(ArrowDownIcon)`
  max-width: 1.5rem;
  min-width: 1.5rem;
`;

const ArrowUp = styled(ArrowRightIcon)`
  max-width: 1.5rem;
  min-width: 1.5rem;
  rotate: 270deg;
`;

const Answer = styled(Text).attrs({
  type: 'bodyM',
})`
  padding-bottom: 1.5rem;
  font-family: Inter;
  -webkit-text-size-adjust: 100%;
`;

const FAQ: FC<PeopleAskUsProps> = () => {
  const [expandedQuestions, setExpandedQuestions] = useState<number[]>([]);
  const childRef = useRef<HTMLDivElement>(null);

  const { isTablet } = useQuery();

  const handleClick = useCallback(
    (index: number) => {
      if (expandedQuestions.includes(index)) {
        setExpandedQuestions(prevState => [
          ...prevState.filter(item => item !== index),
        ]);
        return;
      }
      setExpandedQuestions(prevState => [...prevState, index]);
    },
    [expandedQuestions],
  );

  useEffect(() => {
    if (!childRef.current) return;
  }, [childRef, expandedQuestions]);

  const isExpanded = (index: number) => expandedQuestions.includes(index);

  return (
    <Layout
      seoTitle="FAQ"
      seoDescription="Find out more about Brainway App"
      seoName="FAQ"
    >
      <Wrapper>
        <Container>
          <Text type={isTablet ? 'h2600' : 'h125Rem'} textAlign="center">
            Frequently asked questions
          </Text>
          <List>
            <QuestionContainer expanded={isExpanded(1)}>
              <TitleContainer onClick={() => handleClick(1)}>
                <Question>How does Brainway work?</Question>
                {isExpanded(1) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(1)}>
                <div ref={childRef}>
                  <Answer>
                    Your personalized anti-procrastination plan is a 7-step
                    program designed by behavior change experts and
                    psychologists to help you be your best self. It is crafted
                    using principles of scientifically proven Cognitive
                    Behavioral Therapy (CBT) and behavioral science.
                  </Answer>
                  <Answer>
                    The plan is broken down into 7 parts during which you work
                    on several aspects of mental and physical health
                    step-by-step. Each part guides you through a series of
                    lessons and health experiments designed to help you live
                    your best life.
                  </Answer>
                  <Answer>
                    Each of us is unique - that&apos;s why a personalized
                    approach is the best way to create lasting changes and
                    prevent you from falling back to the same old harmful
                    patterns.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(2)}>
              <TitleContainer onClick={() => handleClick(2)}>
                <Question>Is Brainway right for me?</Question>
                {isExpanded(2) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(2)}>
                <div ref={childRef}>
                  <Answer>
                    If you are looking for answers to these questions, Brainway
                    is perfect for you:
                  </Answer>
                  <Answer>
                    How can I increase my productivity?
                    <br /> How do I maintain focus on important tasks?
                    <br /> Why is it so hard for me to stay organized?
                    <br /> Why can&apos;t I keep my home tidy and clutter-free?
                    <br /> Why am I making careless mistakes or always being so
                    forgetful?
                    <br /> Why am I always stressed or anxious?
                    <br /> How do I stop procrastinating?
                    <br /> Why can&apos;t I reach my goals, no matter how hard I
                    try?
                    <br />
                    <br /> We are here to remove any obstacles that are stopping
                    you from being a focused, productive, and successful person
                    to crush the goals you set for yourself.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(3)}>
              <TitleContainer onClick={() => handleClick(3)}>
                <Question>
                  What about data privacy and confidentiality?
                </Question>
                {isExpanded(3) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(3)}>
                <div ref={childRef}>
                  <Answer>
                    All your data is encrypted and cannot be seen by anyone but
                    yourself. You are in complete control of your data and can
                    delete everything at any time.
                  </Answer>
                  <Answer>
                    Our main priority will always be to help people make healthy
                    decisions - we consider data privacy a top priority and will
                    never breach the trust of our users.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
          </List>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default React.memo(FAQ);

const UnderscoreLink = styled.a`
  text-decoration: underline !important;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  padding: 3rem 1rem;
  position: relative;
  min-height: calc(100vh - 416px);
  height: 100%;
  @media ${tablet} {
    z-index: 0;
    padding: 1.5rem 1rem 3rem 1rem;
  }
`;
